import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./features/Auth/Login";
import ChangePassword from "./features/Auth/ChangePassword";
import Forgotpass from "./features/Auth/Forgotpass";
import Resetpass from "./features/Auth/Resetpass";
import EmailSentPage from "./features/Auth/EmailSentPage";
import Verify from "./features/Auth/Verify";
import Register from "./features/Auth/Register";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import DDNav from "./DDNav";
import Dashboard from "./Dashboard";

function App() {
  const navigate = useNavigate();
  const auth = useSelector((store) => store.auth);

  // console.log(auth);
  return (
    <>
      {auth.isAuthenticated ? (
        <>
          <DDNav />
          <Routes>
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="*" element={<Dashboard />} />
          </Routes>
        </>
      ) : (
        <>
          <Routes>
            <Route path="*" element={<Login />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route path="/Forgotpass" element={<Forgotpass />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/Resetpass" element={<Resetpass />} />
            <Route path="/EmailSentPage" element={<EmailSentPage />} />
            <Route path="/Verify/:vkey" element={<Verify />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
